import LazyLoad from '../services/LazyLoad'
import RouterLogic from "../logics/RouterLogic";
import GuardedLayout from "../views/Layout/GuardedLayout";

export default [
    {
        path: '/',
        component: GuardedLayout,
        beforeEnter: (to, from, next) => {
            RouterLogic.redirect(to, from, next)
        },
        children: [
            // Users
            {
                path: 'users',
                name: 'users',
                component: LazyLoad.localView('User/UserList')
            },

            // Units Groups & Units
            {
                path: 'units-groups',
                name: 'units-groups',
                component: LazyLoad.localView('UnitGroup/index')
            },
            {
                path: 'units-groups/:unitGroupUuid/units',
                name: 'unit-group-units',
                component: LazyLoad.localView('UnitGroup/show')
            },
            {
                path: 'units-groups/:unitGroupUuid/users',
                name: 'unit-group-users',
                component: LazyLoad.localView('UnitGroup/show')
            },
            {
                path: 'units-groups/:unitGroupUuid/units/:unitUuid/categories-groups',
                name: 'unit-categories-groups',
                component: LazyLoad.localView('Unit/show')
            },

            // Categories Groups & Categories
            {
                path: 'categories-groups',
                name: 'categories-groups',
                component: LazyLoad.localView('CategoryGroup/index')
            },
            {
                path: 'categories-groups/:categoryGroupUuid/categories',
                name: 'categories-groups-categories',
                component: LazyLoad.localView('CategoryGroup/show')
            },

            //Unit primary categories
            {
                path: 'unit-primary-categories',
                name: 'unit-primary-categories',
                component: LazyLoad.localView('Unit/PrimaryCategories/index')
            },

            //Unit primary categories groups
            {
                path: 'unit-primary-categories-groups',
                name: 'unit-primary-categories-groups',
                component: LazyLoad.localView('Unit/PrimaryCategories/index')
            },

            //settings
            {
                path: 'settings/google-my-business',
                name: 'settings-google-my-business',
                component: LazyLoad.localView('Setting/index')
            },
            {
                path: 'settings/preprod-db-sync',
                name: 'settings-preprod-db-sync',
                component: LazyLoad.localView('Setting/index')
            },
            {
                path: 'settings/crons',
                name: 'crons',
                component: LazyLoad.localView('Setting/index')
            },
            {
                path: 'settings/upload-to-s3',
                name: 'upload-to-s3',
                component: LazyLoad.localView('Setting/index')
            },

            //Applications
            {
                path: 'apps',
                name: 'apps',
                component: LazyLoad.localView('Application/index')
            },

            //Devices
            {
                path: 'devices',
                name: 'devices',
                component: LazyLoad.localView('Device/index')
            },

            //Applications
            {
                path: 'questions',
                name: 'questions',
                component: LazyLoad.localView('Question/index')
            },

            //Applications
            {
                path: 'topics',
                name: 'topics',
                component: LazyLoad.localView('Topic/index')
            },

            //Reports
            {
                path: 'reports',
                name: 'reports',
                component: LazyLoad.localView('Report/index')
            },
        ],

    },
]
